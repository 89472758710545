import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@mui/joy';
import { Stack } from '@mui/material';
import axios from 'axios';
import { useCallback, useState } from 'react';

export default function MailForm() {
  const [mailName, setMailName] = useState('');
  const [mailEmail, setMailEmail] = useState('');
  const [mailMessage, setMailMessage] = useState('');
  const [mailPhone, setMailPhone] = useState<string | undefined>();
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [doneConfirm, setDoneConfirm] = useState(false);
  const [errorConfirm, setErrorConfirm] = useState(false);

  const normalizeInputForPhone = (value?: string, previousValue?: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const handleSubmitConfirm = useCallback(async () => {
    setLoadingConfirmation(true);

    try {
      const { data } = await axios.post(
        'https://northamerica-northeast1-nettoyage-netstar-web.cloudfunctions.net/netstar-mailer',
        {
          name: mailName,
          email: mailEmail,
          message: mailMessage,
          phone: mailPhone,
        }
      );

      console.log(data);
      setDoneConfirm(true);
      handleResetFields();
    } catch (err) {
      console.log(err);
      setErrorConfirm(true);
    }
    setLoadingConfirmation(false);
  }, [mailName, mailEmail, mailMessage, mailPhone]);

  const handleResetFields = () => {
    setMailName('');
    setMailEmail('');
    setMailMessage('');
    setMailPhone('');
  };
  return (
    <Stack>
      {doneConfirm && (
        <Alert color="success" variant="soft">
          Message envoyé!
        </Alert>
      )}
      {errorConfirm && (
        <Alert color="danger" variant="soft">
          Erreur lors de l'envoi du message. Veuillez réessayer plus tard.
        </Alert>
      )}
      <form>
        <Stack
          spacing={2}
          sx={{ background: '#fff', p: 2, borderRadius: '10px' }}
        >
          <FormControl>
            <FormLabel>Nom complet</FormLabel>
            <Input
              fullWidth
              value={mailName}
              onChange={(e) => setMailName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Courriel</FormLabel>
            <Input
              fullWidth
              value={mailEmail}
              type="email"
              onChange={(e) => setMailEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Téléphone</FormLabel>
            <Input
              fullWidth
              value={mailPhone}
              type="phone"
              onChange={(e) =>
                setMailPhone((prev) =>
                  normalizeInputForPhone(e.target.value, prev)
                )
              }
            />
          </FormControl>
          <Textarea
            placeholder="Message"
            value={mailMessage}
            onChange={(e) => setMailMessage(e.target.value)}
            minRows={5}
          />
          <Stack direction="row" justifyContent="end">
            <Button
              variant="solid"
              onClick={handleSubmitConfirm}
              loading={loadingConfirmation}
            >
              Envoyer
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
