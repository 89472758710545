import Contact from 'pages/Contact';
import Error from 'pages/Error';
import Home from 'pages/Home';
import Carpets from 'pages/services/Carpets';
import Commercial from 'pages/services/Commercial';
import Furniture from 'pages/services/Furniture';
import Tiles from 'pages/services/Tiles';
import { Navigate, useRoutes } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '',
      children: [
        { path: '', element: <Navigate to="/accueil" replace /> },
        { path: '500', element: <Error statusCode={500} /> },
        { path: '404', element: <Error statusCode={404} /> },
        { path: '400', element: <Error statusCode={400} /> },
        { path: 'contact', element: <Contact /> },
        { path: 'accueil', element: <Home /> },
        // { path: 'quote', element: <Quote /> },
        {
          path: 'services',
          children: [
            { path: 'carpets', element: <Carpets /> },
            { path: 'commercial', element: <Commercial /> },
            { path: 'furniture', element: <Furniture /> },
            { path: 'tile', element: <Tiles /> },
          ],
        },
        { path: '*', element: <Error statusCode={404} /> },
      ],
    },
    { path: '*', element: <Error statusCode={404} /> },
  ]);
}
