import { Stack, Typography } from '@mui/material';

type ErrorPageProps = {
  statusCode: number;
};

export default function Error({ statusCode }: ErrorPageProps) {
  return (
    <Stack
      sx={{
        backgroundImage: 'url("/assets/stock_sofa.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 5,
        px: '15%',
        height: 'calc(100vh - 257px)',
      }}
    >
      <Typography variant="h1">{statusCode}</Typography>
      <Typography variant="h6">
        Une erreur s'est produite. {''}
        {statusCode === 404
          ? `La page que vous recherchez n'existe pas.`
          : statusCode === 500
          ? 'Erreur interne du serveur.'
          : 'Requête invalide.'}
      </Typography>
    </Stack>
  );
}
