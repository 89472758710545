import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Stack, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from '../svgs/logo_star.svg';

export default function Footer() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        px: mobile ? '5%' : '15%',
        position: 'relative',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: 'space-between',
          borderTop: '1px solid #ececec',
          py: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {!mobile && <img src={logo} alt="logo" style={{ width: '50px' }} />}
          <Stack>
            <Typography variant="subtitle2">Nettoyage Netstar</Typography>
            <Typography variant="caption">4364 Av. Saint-Augustin</Typography>
            <Typography variant="caption">Lévis, QC G6Z 8K1</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="end"
            alignItems="center"
          >
            <LocalPhoneIcon sx={{ fontSize: '12px' }} />
            <Typography fontWeight={400} fontSize={13}>
              +1 (418) 832-7929
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="end"
            alignItems="center"
          >
            <EmailIcon sx={{ fontSize: '12px' }} />
            <Typography fontWeight={400} fontSize={13}>
              info@nettoyagenetstar.com
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>
    </Stack>
  );
}
