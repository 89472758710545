import { ChevronRight } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/joy';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Tiles() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={3} sx={{ pr: 2 }}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <Typography level="h6" sx={{ color: '#444' }}>
                    Nettoyage de
                  </Typography>
                  <Typography
                    level="h4"
                    fontWeight="bolder"
                    sx={{
                      color: '#0093ab',
                      lineHeight: '0.9',
                      mt: '0px!important',
                    }}
                  >
                    CÉRAMIQUES
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Typography
                    level="h6"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    De propre à éclatant
                  </Typography>
                  <Typography level="body1">
                    Les apparences sont trompeuses. Avec les années, votre
                    céramique pourrait s'être encrassé. Vous pourriez être
                    surpris de revoire la couleur d'origine de votre céramique.
                    Plus que la céramique elle-même, le coulis est un défi à
                    nettoyer pour le simple mortel. Notre technologie de vapeur
                    sous pression donne des résultats étincelants. Vous aurez
                    alors atteint le niveau ultime de propreté et votre
                    céramique vous en remerciera.
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  level="h6"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  Services offerts:
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de céramique résidentiel
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de céramique commercial
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{
              flex: '1 1 auto',
              background: 'url("/assets/stock_tile_kitchen.jpeg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '400px',
            }}
          ></Grid>
        </Grid>
        <Divider />
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography level="h6" fontSize="m2" textColor="inherit">
                Prêt à en profiter?
              </Typography>
              <Typography level="body1" textColor="inherit">
                Demandez dès maintenant un devis{' '}
                <strong style={{ textTransform: 'uppercase' }}>gratuit</strong>{' '}
                pour le nettoyage de votre céramique.
              </Typography>
            </Stack>
            <Button
              variant="solid"
              endDecorator={<ChevronRight />}
              onClick={() => navigate('/contact')}
            >
              Contactez-nous
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}
