import { ChevronRight } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';

export default function Carpets() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={3} sx={{ pr: 2 }}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <Typography level="h6" sx={{ color: '#444' }}>
                    Nettoyage de
                  </Typography>
                  <Typography
                    level="h4"
                    fontWeight="bolder"
                    sx={{
                      color: '#0093ab',
                      lineHeight: '0.9',
                      mt: '0px!important',
                    }}
                  >
                    TAPIS
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Typography level="h6">Plus que de la propreté</Typography>
                  <Typography level="body1">
                    Au delà de se débarrasser d'une tache, nettoyer vos tapis
                    améliore votre qualité de vie. Vos tapis peuvent renfermer
                    des bactéries, des acariens, des moisissures et des
                    allergènes. En plus de les rendre plus propres, notre
                    nettoyage à la vapeur combiné à des produits anti-allergènes
                    de haute qualité débarrassons vos tapis de ces mauvais
                    éléments, permettant à votre famille de mieux respirer.
                  </Typography>
                </Stack>
                <Stack>
                  <Typography level="h6">Cure rajeunissante</Typography>
                  <Typography level="body1">
                    Au delà de se débarrasser d'une tache, nettoyer vos tapis
                    améliore votre qualité de vie. Notre technologie de camions
                    usines nettoient en profondeur le tapis, pour lui redonner
                    un air de jeunesse. Vos tapis seront comme neufs et le
                    resterons plus longtemps.
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  level="body3"
                  fontSize="13px"
                  textTransform="uppercase"
                  textColor="#333"
                  fontWeight="bold"
                >
                  Services offerts:
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de tapis résidentiel
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de tapis commercial
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{
              flex: '1 1 auto',
              background: 'url("/assets/stock_carpet_kid_dog.jpeg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '400px',
            }}
          ></Grid>
        </Grid>
        <Divider />
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography level="h6" fontSize="m2" textColor="inherit">
                Prêt à en profiter?
              </Typography>
              <Typography level="body1" textColor="inherit">
                Demandez dès maintenant un devis{' '}
                <strong style={{ textTransform: 'uppercase' }}>gratuit</strong>{' '}
                pour le nettoyage de vos tapis.
              </Typography>
            </Stack>
            <Button
              variant="solid"
              endDecorator={<ChevronRight />}
              onClick={() => navigate('/contact')}
            >
              Contactez-nous
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}
