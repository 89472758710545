import { ChevronRight } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/joy';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Commercial() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={3} sx={{ pr: 2 }}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <Typography level="h6" sx={{ color: '#444' }}>
                    Entretiens des
                  </Typography>
                  <Typography
                    level="h4"
                    fontWeight="bolder"
                    sx={{
                      color: '#0093ab',
                      lineHeight: '0.9',
                      mt: '0px!important',
                    }}
                  >
                    ESPACES COMMERCIAUX
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Typography
                    level="h6"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    Service clé en main
                  </Typography>
                  <Typography level="body1">
                    Que ce sois pour un entretien quotidien, hebdomadaire ou
                    tout simplement un grand ménage, notre équipe dévouée
                    prendra soin de votre aire de travail.
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  level="h6"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  Services offerts:
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Lavage de vitres
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Lavage de planchers
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Entretien des salles de bain
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Epoussetage de bureaux
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de tapis
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de vos ameublements: chaises, fauteuils, etc.
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Décapage de plancher
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Balayage et aspirateur
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Autres services disponibles sur mesure
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{
              flex: '1 1 auto',
              background: 'url("/assets/stock_office.jpeg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '400px',
            }}
          ></Grid>
        </Grid>
        <Divider />
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography level="h6" fontSize="m2" textColor="inherit">
                Prêt à en profiter?
              </Typography>
              <Typography level="body1" textColor="inherit">
                Demandez dès maintenant un devis{' '}
                <strong style={{ textTransform: 'uppercase' }}>gratuit</strong>{' '}
                pour l'entretiens de vos espaces commerciaux.
              </Typography>
            </Stack>
            <Button
              variant="solid"
              endDecorator={<ChevronRight />}
              onClick={() => navigate('/contact')}
            >
              Contactez-nous
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}
