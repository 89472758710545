import { ChevronRight } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/joy';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Furniture() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={3} sx={{ pr: 2 }}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <Typography level="h6" sx={{ color: '#444' }}>
                    Nettoyage de
                  </Typography>
                  <Typography
                    level="h4"
                    fontWeight="bolder"
                    sx={{
                      color: '#0093ab',
                      lineHeight: '0.9',
                      mt: '0px!important',
                    }}
                  >
                    MEUBLES REMBOURRÉS
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Typography
                    level="h6"
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    Un nettoyage en profondeur
                  </Typography>
                  <Typography level="body1">
                    Vos meubles font beaucoup pour vous. Ils vous supportent,
                    vous bercent, vous reposent... Ils méritent à leur tour ce
                    qu'il y a de mieux. Laissez Nettoyage Netstar les rajeunir
                    avec un nettoyage en profondeur. Nous pouvons libérer vos
                    meubles des taches, des odeurs et de la saleté, même
                    impercevable à l'oeil nu! Notre service saura vous convenir,
                    à vous et vos meubles. Notre expérience nous permet de
                    nettoyer tous les recouvrements sur le marché : cuir, fibres
                    synthétiques et naturelles, polypropylène, oléfine, etc.
                    Pour retrouver une aise optimale l'orsque vous vous asseyez
                    devant le téléviseur, appelez-nous!
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  level="h6"
                  textTransform="uppercase"
                  textColor="#333"
                  fontWeight="bold"
                >
                  Services offerts:
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de meubles rembourés
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de chaises
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de divans / sofa
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de canapé
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de causeuse
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de fauteuil
                </Typography>
                <Typography
                  level="body3"
                  fontSize="12px"
                  textTransform="uppercase"
                  textColor="#333"
                >
                  Nettoyage de matelas
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{
              flex: '1 1 auto',
              background: 'url("/assets/stock_couch_clean.jpeg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '400px',
            }}
          ></Grid>
        </Grid>
        <Divider />
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography level="h6" fontSize="m2" textColor="inherit">
                Prêt à en profiter?
              </Typography>
              <Typography level="body1" textColor="inherit">
                Demandez dès maintenant un devis{' '}
                <strong style={{ textTransform: 'uppercase' }}>gratuit</strong>{' '}
                pour le nettoyage de vos meubles rembourrés.
              </Typography>
            </Stack>
            <Button
              variant="solid"
              endDecorator={<ChevronRight />}
              onClick={() => navigate('/contact')}
            >
              Contactez-nous
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}
