import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ContactIcon from 'components/contact/ContactIcon';
import MailForm from 'components/contact/MailForm';

export default function Contact() {
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container>
          <Grid xs={12} md={12} lg={12}>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography level="h6" sx={{ color: '#444' }}>
                Contactez
              </Typography>
              <Typography
                level="h4"
                fontWeight="bolder"
                sx={{
                  color: '#0093ab',
                  lineHeight: '0.9',
                  mt: '0px!important',
                }}
              >
                NETTOYAGE NETSTAR
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Stack>
              <Stack
                spacing={3}
                sx={{
                  background: '#0068b1',
                  color: '#fff',
                  p: 3,
                  borderRadius: '10px 0px 0px 0px',
                }}
              >
                <Stack>
                  <Typography level="h6" fontWeight="bold" textColor="#fff">
                    Pour nous joindre
                  </Typography>
                  <Typography level="body2" textColor="#fff">
                    Pour de l'information, contactez-nous par courriel ou
                    téléphone.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <ContactIcon
                    icon={<LocalPhoneIcon fontSize="small" />}
                    contactValue="+1 (418) 832-7929"
                  />
                  <ContactIcon
                    icon={<AlternateEmailIcon fontSize="small" />}
                    contactValue="info@nettoyagenetstar.com"
                  />
                  <ContactIcon
                    icon={<HomeIcon fontSize="small" />}
                    contactValue="4364 Avenue Saint-Augustin, Lévis, QC G6Z 8K1"
                  />
                </Stack>
              </Stack>
              <Stack
                spacing={3}
                sx={{
                  background: '#3e4042',
                  color: '#fff',
                  borderRadius: '0px 0px 0px 10px',
                  overflow: 'hidden',
                }}
              >
                <iframe
                  title="Google Maps"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB6uXE-wt_dSTDTNDbs3hGtiVjFI07vEAI&q=place_id:ChIJD_1-vmCNuEwRhNEtYx69j3U"
                ></iframe>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={8} display="flex">
            <Stack spacing={1}>
              <Stack
                spacing={3}
                sx={{
                  background: '#001e3c',
                  color: '#fff',
                  p: 3,
                  borderRadius: '0px 10px 10px 0px',
                  flex: '1 1 auto',
                }}
              >
                <Stack>
                  <Typography level="h6" fontWeight="bold" textColor="#fff">
                    Écrivez-nous
                  </Typography>
                  <Typography level="body2" textColor="#fff">
                    Vous pouvez remplir le formulaire ci-dessous pour nous
                    envoyer un message et nous vous contacterons dans les plus
                    brefs délais.
                  </Typography>
                </Stack>
                <MailForm />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
