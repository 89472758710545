import { Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import { ReactElement } from 'react';

export default function ContactIcon({
  icon,
  contactValue,
}: {
  icon: ReactElement;
  contactValue: string;
}) {
  return (
    <Stack direction={'row'} alignItems="center" spacing={2}>
      <Stack
        direction={'row'}
        justifyContent="center"
        alignItems={'center'}
        sx={{
          background: '#003b77',
          p: '5px',
          color: '#fff',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          flex: '0 0 auto',
        }}
      >
        {icon}
      </Stack>
      <Typography
        level="body2"
        fontSize={17}
        fontWeight="normal"
        textColor="#fff"
      >
        {contactValue}
      </Typography>
    </Stack>
  );
}
